/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_4threal/layout/core'
import { useDispatch } from 'react-redux'
import { deleteCard, getOrders, getDeletedCards, updateCard } from '../../../redux/action/card'
import { useSelector } from 'react-redux'
import PaymentWarning from '../../../_4threal/layout/components/sidebar/PaymentWarning'
import { userProfile } from '../../../redux/action/auth'
import { getPlanById } from '../../../redux/action/plan'
import CheckActivePlan from '../Plandetail/ActivePlan/CheckActivePlan'
import DeleteCardModal from './modal/DeleteCardModal'
import './dashboard.css'
import { Button } from 'react-bootstrap'

const OrderPage: FC = () => {
  const currentPage = 1  //omitting useState hook due to eslint warning
  const itemsPerPage = 50 // Number of items to display per page
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const type = 'delete' //not used
  const cardId = '' //not used
  const currCard = {} //not used
  const Allcarddata = useSelector((state: any) => state.Card?.UserOrders)
  const [Allcard, setAllcard] = React.useState<any>([])
  const userId = useSelector((state: any) => state?._id)
  const currentPlan = useSelector((state: any) => state?.Auth?.authProfile?.payments)
  const activePlan = CheckActivePlan(currentPlan)
  const [pname, setPname] = React.useState("")
  const token = localStorage.getItem('userId')
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [currOrder, setCurrOrder] = React.useState<any>({})
  const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });

  useEffect(() => {
    if (token) {
      dispatch(userProfile(token))
      dispatch(getPlanById(token))
    }
  }, [])

  useEffect(() => {
    if (Allcarddata) {
      setAllcard(Allcarddata)
    }
  }, [Allcarddata])

  const handleGetAllCard = () => {
    dispatch(getOrders({ currentPage, itemsPerPage, userId }))
  }

  function timeSince(timestamp) {
    const now = new Date().getTime()
    const secondsPast = (now - timestamp) / 1000

    if (secondsPast < 60) {
      // Less than a minute
      return `${Math.round(secondsPast)} seconds ago`
    } else if (secondsPast < 3600) {
      // Less than an hour
      return `${Math.round(secondsPast / 60)} minutes ago`
    } else if (secondsPast <= 86400) {
      // Less than a day
      return `${Math.round(secondsPast / 3600)} hours ago`
    } else {
      // More than a day
      return `${Math.round(secondsPast / 86400)} days ago`
    }
  }

  function timeUntil(utcTimestamp) {
    const now = new Date().getTime();
    const targetTime = new Date(utcTimestamp).getTime();
    const secondsUntil = (targetTime - now) / 1000;

    if (secondsUntil < 0) {
      return 'Today';
    } else if (secondsUntil < 60) {
      // Less than a minute
      return `Today`;
    } else if (secondsUntil < 3600) {
      // Less than an hour
      return `Today`;
    } else if (secondsUntil <= 86400) {
      // Less than a day
      return `Today`;
    } else {
      // More than a day
      return `in ${Math.round(secondsUntil / 86400)} days`;
    }
  }

  useEffect(() => {
    handleGetAllCard()
  }, [currentPage, itemsPerPage, userId])

  const handleDelete: any = (id: any) => {
    const updatedData = Allcard?.map((data) => {
      if (data?._id === cardId) {
        return { ...data, isDeleted: true }
      } else {
        return data
      }
    })
    setAllcard(updatedData)
    setOpen(false)
    dispatch(deleteCard(cardId)).then(() => {
      handleGetAllCard()
      dispatch(getDeletedCards())
    })
  }

  const handleRename: any = () => {
    let card = {}
    const updatedData = Allcard?.map((data) => {
      if (data?._id === cardId) {
        card = { ...data, name: pname };
        return card
      } else {
        return data
      }
    })

    setAllcard(updatedData)
    setOpen(false)
    dispatch(updateCard(card)).then(() => {
      handleGetAllCard()
    })
  }

  const handleTextChange = (value) => {
    setPname(value);
  }

  // const handleOpen = (id: any, type: string) => {
  //   setOpen(true)
  //   setType(type)
  //   setCardId(id)
  //   let card = Allcard?.filter((data) => data?._id === id)
  //   setCard(card[0])
  //   if (card?.name) {
  //     setPname(card?.name)
  //   } else {
  //     setPname("")
  //   }
  // }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDownload = async (fileUrl, id) => {
    // File name for the downloaded file
    const fileName = `order-${id}.png`;

    // Create a temporary anchor element
    try {
      // Fetch the image
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the Blob URL
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  }

  const cardTypeToString = (cardType) => {
    switch (cardType) {
      case 'bc': return 'Business Card'
      case 'pc': return 'Post Card'
      case 'qr': return 'Sticker'
      default: return 'Custon'
    }
  }

  return (
    <div className='row w-100 m-auto py-3 relative'>
      {Allcard?.map((e, i) => {
        if ('printUrl' in e && e?.printUrl) {
          return (
            <div className='col-12 col-md-4 col-lg-3 my-2' key={i}>
              <div className='w-100 h-250px position-relative border rounded d-flex flex-column justify-content-center align-items-center p-15'>
                <div className='cur-pointer' onClick={() => { setIsModalOpen(true); setCurrOrder(e); }} >
                  <img style={{ filter: 'drop-shadow(5px 5px 4px #222222)' }} className='h-100 w-100' src={e.printUrl} alt=''/>
                  <div style={{ top: '-10px', left: '-10px' }} className='position-absolute'>
                    <div style={{ textTransform: 'capitalize' }} className='pill-btn'>{e.status}</div>
                  </div>
                </div>
                {/*
                <div style={{ top: '6px', right: '1px' }} className='position-absolute drop-arrow-hide'>
                  <button className="bg-transparent border-0 dropdown-toggle dropdown-toggle-split more-hover" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="svg-container">
                      <svg className="svg-icon" width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.599609 2.00002C0.599609 1.1162 1.31578 0.400024 2.19961 0.400024C3.08344 0.400024 3.79961 1.1162 3.79961 2.00002C3.79961 2.88385 3.08344 3.60002 2.19961 3.60002C1.31578 3.60002 0.599609 2.88385 0.599609 2.00002Z" fill="#444444" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.39941 2.00002C7.39941 1.1162 8.11559 0.400024 8.99941 0.400024C9.88324 0.400024 10.5994 1.1162 10.5994 2.00002C10.5994 2.88385 9.88324 3.60002 8.99941 3.60002C8.11559 3.60002 7.39941 2.88385 7.39941 2.00002Z" fill="#444444" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.1992 2.00002C14.1992 1.1162 14.9154 0.400024 15.7992 0.400024C16.683 0.400024 17.3992 1.1162 17.3992 2.00002C17.3992 2.88385 16.683 3.60002 15.7992 3.60002C14.9154 3.60002 14.1992 2.88385 14.1992 2.00002Z" fill="#444444" />
                      </svg>
                      <svg className="overlay-icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15" cy="15" r="15" fill="black" fillOpacity="0" />
                      </svg>
                    </div>
                  </button>
                  <ul className="dropdown-menu p-4 mt-3 w-100 px-3">
                    <li className='cursor-pointer' onClick={() => handleOpen(e?.cardId, 'reorder')}>
                      <div className='d-flex gap-2 align-items-center'>
                        <img width="25" src="media/reorder.svg" alt="" />
                        Re-order
                      </div>
                    </li>
                  </ul>
                </div>
                */}
                <div style={{ fontSize: '11px', bottom: '-25px', left: '6px' }} className='position-absolute'>
                  Order # {e?._id}
                </div>
                <div style={{ fontSize: '12px', top: '25px', left: '6px' }} className='position-absolute'>
                  Estimated Fullfillment: {e?.estimated_fullfillment ? timeUntil(e?.estimated_fullfillment) : '1 week'}
                </div>
                <div style={{ fontSize: '12px', bottom: '6px', left: '6px' }} className='position-absolute'>
                  Will Ship Via: {e?.shipping_type ? e?.shipping_type.split('_')[0] : 'USPS'}
                </div>
              </div>
              <div>
                <div className="row">
                  <div style={{ fontSize: '11px', textAlign: 'right' }} className='my-2 font-gray1 col-12'>
                    {timeSince(e.createdAt)}
                  </div>
                </div>
              </div>
            </div>
          )
        }
        else return <></>
      })}

      {isModalOpen &&
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1001 }}>
          <div style={{ width: '330px', height: '500px', backgroundColor: '#fff', borderRadius: '8px' }}>
            <img src={currOrder?.printUrl} style={{ width: '100%', marginTop: '10px' }} alt=''/>
            <div className='row m-2 fs-4'>
              <div className='col'>Order ID:</div>
              <div className='col fs-8 m-auto'>{currOrder?._id}</div>
            </div>
            <div className='row m-2 fs-4'>
              <div className='col'>Print Type:</div>
              <div className='col'>{cardTypeToString(currOrder?.cardType)}</div>
            </div>
            <div className='row m-2 fs-4'>
              <div className='col'>Purchase Date:</div>
              <div className='col'>{formatter.format(new Date(currOrder?.createdAt))}</div>
            </div>
            <div className='row m-2 fs-4'>
              <div className='col'>Total Paid:</div>
              <div className='col'>${currOrder?.total_paid}</div>
            </div>
            <Button className='col m-4' variant="primary" onClick={() => setIsModalOpen(false)}>Close</Button>
            <Button className='col m-4' variant="primary" onClick={() => handleDownload(currOrder?.printUrl, currOrder?.cardId)}>Download PNG</Button>
          </div>
        </div>
      }

      {activePlan?.length === 0 && <PaymentWarning />}
      <DeleteCardModal open={open} type={type} card={currCard} handleClose={handleClose} del={handleDelete} rename={handleRename} rtext={handleTextChange} pname={pname} />
    </div>
  )
}

const OrderWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <OrderPage />
    </>
  )
}

export { OrderWrapper }
