/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBlobUrl, getCard, getMarker } from '../../redux/action/card'
import { useDispatch, useSelector } from 'react-redux';
import { AppLog } from '../../_4threal/helpers';
import './video_overlay.css';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import StatusOverlay from './status'
import { VERTEX_SHADER, FRAGMENT_SHADER } from './shaders'
import { MindARThree } from './three';
import { getFunctions, httpsCallable } from 'firebase/functions'
import { ReadJsonAtUrl } from '../../_4threal/helpers/ReadJsonAtUrl';
import { generatePopup } from '../../utils/popup';
import { Action } from '../../redux/actionTypes';
import { Step } from '../modules/cardform/components/editors/WalkthroughSteps';
import {
  MeshStandardMaterial, Color, ShaderMaterial,
  AmbientLight, DirectionalLight, DoubleSide, Clock, AnimationMixer, Vector2,
  Raycaster, TextureLoader, VideoTexture, PlaneGeometry, ObjectLoader,
  MeshBasicMaterial, Mesh, LinearFilter, RGBAFormat, LineBasicMaterial, BufferGeometry, Line, SRGBColorSpace, sRGBEncoding, Shape, ExtrudeGeometry, MeshPhongMaterial
  // Scene, PerspectiveCamera, BoxGeometry, MeshPhongMaterial, WebGLRenderer, Math, Box3, Vector3, BoxGeometry,
} from 'three';

// import { CSS3DObject, CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
// import { fontConvert } from './fontConvert';
// interface DeviceOrientationEvent {
//   requestPermission: () => Promise<string>;
// }

function ARScene(props: any = { isPreview: false, currCard: {}, captureScreenshot: null, customScene: {} }) {
  const { isPreview, currCard, captureScreenshot, customScene } = props;
  const begMindUrl = 'https://firebasestorage.googleapis.com/v0/b/xtyndedreality.appspot.com/o/placeholders%2Fplaceholder.mind?alt=media&token=b331d467-7b0d-4ddd-9f39-4a68c8ecabdc'
  const begVideoUrl = 'https://firebasestorage.googleapis.com/v0/b/xtyndedreality.appspot.com/o/placeholders%2Fplaceholder.video.mp4?alt=media&token=463d8eb6-8ee8-4bfb-af9d-1c2a4c41345a'
  const begLogoUrl = '/media/ar/yourlogo.png'
  const [hasOrientationPermission, setHasOrientationPermission] = useState(false);
  const [isArInstanceStarted, setIsArInstanceStarted] = useState(false);
  const sceneRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const functions = getFunctions()
  const reportAnalytics = httpsCallable(functions, 'reportAnalytics')
  const audioRefs: any = useRef([]);
  const currAssets: any = useRef({});
  const currLinks: any = useRef({});
  const currMarker: any = useRef({});
  const isDemo: any = useRef(false);
  const scard = useSelector((state: any) => state.Card?.card);
  const smarker = useSelector((state: any) => state.Card?.marker);
  const [arInstance, setArInstance] = useState<MindARThree>();
  const [renderer, setRenderer] = useState<any>(null);
  const [scene, setScene] = useState<any>(null);
  const [camera, setCamera] = useState<any>(null);
  const [target, setTarget] = useState<ArrayBuffer>();
  const [targetFound, setTargetFound] = useState(false);
  const [markerImg, setMarkerImg]: any = useState(null);
  const [isLoading, setIsLoading]: any = useState(true);
  const [isGettingCamera, setIsGettingCamera]: any = useState(false);
  const [isStartingAr, setIsStartingAr]: any = useState(false);
  const [progress, setProgress]: any = useState(0);
  //const [isScanning, setIsScanning]: any = useState(false);
  const isProcessing = false; // omitting setIsProcessing due to eslint warning
  const [isReady, setIsReady]: any = useState(false);
  const [needsTap, setNeedsTap]: any = useState(true);
  const needsTapRef = useRef(true);
  const contentVisibleRef = useRef(false);
  const isScanningRef = useRef(false);
  const [isScanning, setIsScanning]: any = useState(false);
  const [isError, setIsError]: any = useState('');
  const [isMuted, setIsMuted]: any = useState(true);
  const [isPublished, setIsPublished]: any = useState(false);
  const mouse: any = useRef(new Vector2());
  const canAcceptRaycast = useRef(false);
  const anchor: any = useRef(null);
  const debugLine: any = useRef(null);
  const debugClicks: any = useRef(false);
  const [lastLinks, setLastLinks]: any = useState('[]');
  const [socialLinks, setSocialLinks]: any = useState([]);
  const [lastCard, setLastCard]: any = useState('{}');
  //const [contentVisible, setContentVisible] = useState(false);
  const allowUnanchored = useRef(true); // omitting setAllowUnanchored due to eslint warning

  useEffect(() => { addCustomStuffToScene() }, [customScene])

  const addCustomStuffToScene = async () => {
    if (arInstance && customScene._id !== undefined) {
      if (customScene._id === 'CLEAR') {
        const objectInARScene = scene.getObjectByName('CustomScene');
        scene.remove(objectInARScene);
      } else {
        const jsonUrl = await dispatch(
          getBlobUrl({
            filename: `${customScene._id}.${customScene.scene.name}`,
            dirs: ['assets'],
            setUser: true,
          })
        );

        if (jsonUrl === Action.GET_BLOB_URL_FAIL) {
          generatePopup('error', '3D scene failed to load (the project may not exist in database)');
        } else {
          const jsonFromFirebase = await ReadJsonAtUrl(jsonUrl);
          console.log(jsonFromFirebase);
          //Remove the active scene first (don't want to add too many lights)
          const objectInARScene = scene.getObjectByName('CustomScene');
          scene.remove(objectInARScene);

          //Parse the json then add to the scene
          const loader = new ObjectLoader();
          const object = loader.parse(jsonFromFirebase.scene);
          object.name = 'CustomScene'; //name the object for future removal
          scene.add(object);

          generatePopup('success', 'Project is has been loaded');
        }
      }
    }
  }

  const checkTransparency = (imageUrl: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Use as needed for cross-origin images
      img.src = imageUrl;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx: any = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          // data[i + 3] is the alpha component of the pixel
          if (data[i + 3] === 0) {
            // Transparent pixel, make it black
            data[i] = 0;     // Red
            data[i + 1] = 0; // Green
            data[i + 2] = 0; // Blue
            data[i + 3] = 255; // Fully opaque
          } else {
            // Opaque pixel, make it white
            data[i] = 255;     // Red
            data[i + 1] = 255; // Green
            data[i + 2] = 255; // Blue
            // Alpha stays the same
          }
        }

        // Put the modified data back on the canvas
        ctx.putImageData(imageData, 0, 0);

        // Convert canvas to image URL
        const resultImageSrc = canvas.toDataURL();
        resolve(resultImageSrc);
      };

      img.onerror = (error) => {
        console.error("Error loading image:", error);
        resolve(null);
      };
    });
  };

  useEffect(() => {
    Object.values(currAssets.current).forEach((asset: any) => {
      if (asset.type === 'video') {
        asset.ref.muted = isMuted;
      } else if (asset.type === 'audio') {
        asset.ref.muted = isMuted
      }
    })
  }, [isMuted])

  useEffect(() => {
    // console.log('id', id)
    if (!isPreview) {
      const idsp: any = id?.split('-');
      if (idsp.length > 1) {
        if (idsp[1] === 's') {
          dispatch(getCard({ id: idsp[0] }));
          isDemo.current = true;
        }
        // setCardId(idsp[0]);
      } else {
        console.log('getting marker')
        dispatch(getMarker({ id: idsp[0] }));
      }
    }
  }, [id]);

  // const saveAnalytics = (index: any) => {
  //   const newLinkKey = `link${index}`;
  //   dispatch(updateAnalytics({
  //     links: {
  //       [newLinkKey]: 1,
  //     }
  //   }, cardId))
  // };

  useEffect(() => {
    console.log('ARScene scard', scard)

    if (Object.keys(scard).length) {
      if (!isPreview) {
        AppLog('scard', scard)
        if (scard?.protected?.isActive || isDemo.current) {
          loadContent(scard)
          setIsPublished(true);
        } else {
          setIsPublished(false);
          //navigate("/")
        }
      } else {
        console.log('preview', scard)
        loadContent(scard)
      }
    } else {
      if (currCard) {
        loadContent(currCard)
      }
    }
  }, [scard]);

  useEffect(() => {
    if (!isPreview) {
      if(smarker) {
        if (Object.keys(smarker).length) {
          AppLog('smarker', smarker)
          currMarker.current = smarker;
          dispatch(getCard({ id: smarker?.sceneId }));

          if (smarker?.protected?.isActive) {
            //loadContent(smarker)
            //setIsPublished(true);
          } else {
            //setIsPublished(false);
            ////navigate("/")
          }
        }
      } else {
        generatePopup('error', 'Cannot load, a marker MUST be attributed to this AR scene')
      }
    }
  }, [smarker]);

  const parseUrl = (url: string) => {
    let parsed = url;

    if (url && process.env.REACT_APP_LOCAL_IP && url.includes('http://localhost:9199')){
       parsed = url.replace('http://localhost:9199', 'http://' + process.env.REACT_APP_LOCAL_IP + ':9199')
    }
    return parsed;
  }

  const addAsset = async (asset: any, assetId: string, load = false) => {
    console.log(asset);
    return new Promise(async (resolve, reject) => {
      let thisneedsTap = false

      asset['url'] = parseUrl(asset.url)
      console.log('adding asset', asset.url)
      if (asset.type === 'video') {
        const video: any = await loadVideo(asset);
        let entry = {
          ref: video.ref,
          type: 'video',
          canplay: video.canplay,
          asset: asset,
        }
        if (!video.canplay) {
          thisneedsTap = true;
        }
        if (load) {
          if (!needsTap) {
            const mesh = makeVideo(entry, assetId);
            entry['mesh'] = mesh;
            video.ref.currentTime = 4;
            video.ref.play();
            captureScreenshot(video.ref, currCard, assetId);
          }
        }
        currAssets.current[assetId] = entry;
      } else if (asset.type === 'audio') {
        const audio: any = await loadAudio(asset);
        let entry = {
          ref: audio.ref,
          type: 'audio',
          canplay: audio.canplay,
          asset: asset,
        }
        if (!audio.canplay) {
          thisneedsTap = true;
        }

        if (load) {
          if (!needsTap) {
            audio.ref.play();
          }
        }
        currAssets.current[assetId] = entry;

      } else if (asset.type === 'model') {
        const model: any = await loadModel(asset.url);

        let entry = {
          ref: model.scene,
          animations: model.animations,
          type: 'model',
          asset: asset,
        }

        if (load) {
          const mesh = make3Dmodel(entry, assetId);
          entry['mesh'] = mesh;
        }
        currAssets.current[assetId] = entry;

      } else if (asset.type === 'image') {
        const image: any = await loadTexture(asset.url);
        const normalmap: any = await checkTransparency(asset.url);
        let entry = {
          ref: image,
          type: 'image',
          normalmap: normalmap,
          asset: asset,
        }

        if (load) {
          const mesh = makeLogo(entry, assetId);
          entry['mesh'] = mesh;
        }
        currAssets.current[assetId] = entry;
      } else if (asset.type === 'text') {
        const font: any = await loadFont(asset.url);
        let entry = {
          ref: font,
          type: 'text',
          asset: asset,
        }

        if (load) {
          makeText(entry, assetId);
        }
        currAssets.current[assetId] = entry;
      }
      /*
      if (asset?.linkUrl){
        setLinkObjects([...linkObjects, currAssets.current[assetId].ref]);
        setLinkIds([...linkIds, assetId]);
        setLinks([...links, asset.linkUrl]);
      }
      */
      resolve(thisneedsTap)
    })
  }

  const removeAsset = (assetId: string) => {
    console.log('removing asset', assetId)
    if (currAssets.current[assetId]) {
      if (currAssets.current[assetId].type === 'video') {
        currAssets.current[assetId].ref.pause();
      } else if (currAssets.current[assetId].type === 'audio') {
        currAssets.current[assetId].ref.pause();
      }
      const currAsset = anchor.current.group.getObjectByName(assetId);
      if (currAsset) {
        anchor.current.group.remove(currAsset);
      }
      delete currAssets.current[assetId];
    }
  }

  const onMouseMove = (event) => {
    const bounds = renderer.domElement.getBoundingClientRect();

    canAcceptRaycast.current =
      (bounds.left < event.clientX && event.clientX < bounds.right) &&
      (bounds.top < event.clientY && event.clientY < bounds.bottom);

    if (canAcceptRaycast.current) { //small optimization
      // Adjust for scrolling by removing window.scrollX and window.scrollY
      mouse.current.x = ((event.clientX - bounds.left) / bounds.width) * 2 - 1;
      mouse.current.y = -((event.clientY - bounds.top) / bounds.height) * 2 + 1;
    }
  }

  const onLinkClick = (event) => {
    console.log('clicked')
    //event.preventDefault();
    //event.stopPropagation();
    if ((!needsTapRef.current && contentVisibleRef.current) || isPreview) {

      if (mouse.current && camera && canAcceptRaycast.current) {
        console.log('click passed')
        const traycaster = new Raycaster();
        traycaster.setFromCamera(mouse.current, camera);
        console.log(traycaster.ray.origin, traycaster.ray.direction);

        let linkObjs: any = [];
        let linkIds: any = {};

        anchor.current.group.traverse((child) => {
          if (child.isMesh && child?.userData?.linkUrl) {

            linkObjs.push(child);
            linkIds[child.uuid] = child.userData.linkUrl;
          }
        });

        linkObjs.forEach(obj => {
          //console.log('linkobj', obj);
        });

        console.log('Mouse:', mouse.current.x, mouse.current.y);
        console.log('Mouse:', mouse.current.x, mouse.current.y);

        if (debugClicks.current) {
          if (debugLine.current) {
            scene.remove(debugLine.current);
            debugLine.current.geometry.dispose();
            debugLine.current.material.dispose();
            debugLine.current = null;
          }

          // Create a new debug line representing the ray
          const lineMaterial = new LineBasicMaterial({ color: 0xff0000 });
          const lineGeometry = new BufferGeometry().setFromPoints([
            traycaster.ray.origin,
            traycaster.ray.origin.clone().add(traycaster.ray.direction.clone().multiplyScalar(100)) // Adjust scalar as needed
          ]);
          debugLine.current = new Line(lineGeometry, lineMaterial);
          scene.add(debugLine.current);
        }

        const intersects = traycaster.intersectObjects(linkObjs, true);
        AppLog('intersects', intersects)
        for (var i = 0; i < intersects.length; i++) {
          console.log('intersect', intersects[i].object)
          if (intersects[i].object.uuid in linkIds) {
            console.log('link', linkIds[intersects[i].object.uuid])
            if (!isPreview && !isDemo.current) {
              /*
              triggerAnalytics('click', linkIds[intersects[i].object.uuid]).then(() => {
                window.open(linkIds[intersects[i].object.uuid])
              }).catch((err) => {
                console.log('analytics error', err)
              })
                */
              triggerAnalytics('click', linkIds[intersects[i].object.uuid])
            }

            window.open(linkIds[intersects[i].object.uuid], '_blank');
            break;
          }
        }
      }
    }
  }

  useEffect(() => {
    if (renderer && renderer?.domElement) {
      document.addEventListener('mousemove', onMouseMove);
    }
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    }
  }, [renderer])

  useEffect(() => {
    //onst handleClick = (event) => {
    // onLinkClick(event);
    ///;
    if (camera) {
      document.addEventListener('click', onLinkClick);
    }
    return () => {
      document.removeEventListener('click', onLinkClick);
    }
  }, [camera])

  useEffect(() => {
    if (!currCard) return;

    /*
    const loadText = async (text) => {
      setCurrLogoText(currCard?.logoText);
      if (!currFont) {
        loadFont().then((font) => {
          setCurrFont(font);
          makeText(currCard?.logoText);
        }).catch((err) => {
          AppLog('error', err)
        })
      } else {
        makeText(currCard?.logoText);
      }
    }
    */
    const currCardString = JSON.stringify(currCard);
    // AppLog('currCard', currCardString);
    const assetsString = JSON.stringify(currCard?.sceneAssets);
    //console.log('currCardString', currCardString)
    //console.log('lastCard', lastCard)
    if (currCardString === lastCard) {
      console.log('same card')
      return;
    }

    if (!arInstance) {
      console.log('no ar instance')
      return;
    }
    console.log(currCard)
    const currLastCard = JSON.parse(lastCard);
    const lastCardAssets = JSON.stringify(currLastCard?.sceneAssets);

    setSocialLinks(currCard?.socials);
    async function updateAssets() {
      //delete assets not in current card
      Object.keys(currLastCard.sceneAssets).forEach((assetId) => {
        if (!(assetId in currCard.sceneAssets)) {
          removeAsset(assetId);
        }
      })

      Object.keys(currCard.sceneAssets).forEach((assetId) => {
        // if (!(assetId in currLastCard.sceneAssets)) {

        console.log('updateAssets', JSON.stringify(currCard?.sceneAssets[assetId]));
        const asset = currCard?.sceneAssets[assetId];
        if (asset.type === 'text') {
          addAsset(asset, assetId, true);
        } else {
          if (assetId === 'userVideo' && 'userVideo' in currLastCard.sceneAssets) {
            if (currCard?.sceneAssets['userVideo'].url !== currLastCard?.sceneAssets['userVideo'].url) {
              currAssets.current['userVideo'].ref.src = currCard.sceneAssets['userVideo'].url;
              currAssets.current['userVideo'].ref.currentTime = 4;
              currAssets.current['userVideo'].ref.play();
              captureScreenshot(currAssets.current['userVideo'].ref, currCard, 'userVideo');
            }
          } else {
            const lastAsset = currLastCard?.sceneAssets[assetId];

            if (!(assetId in currLastCard.sceneAssets)) {
              console.log('adding Asset', assetId)
              addAsset(asset, assetId, true);
            } else {
              console.log('updating asset', assetId)

              const lastAssetString = JSON.stringify(asset);
              const currAssetString = JSON.stringify(lastAsset);

              if (lastAssetString !== currAssetString) {
                console.log('assets dont match', assetId)
                console.log(currAssets.current)
                currAssets.current[assetId]['asset'] = asset;
                const assetRef = currAssets.current[assetId].ref;

                const sceneref = anchor.current.group.getObjectByName(assetId);

                if ((asset?.chromaKeyColor !== lastAsset?.chromaKeyColor)) {
                  console.log('chromacolor mismatch', assetId)
                  if (!asset?.chromaKeyColor || !lastAsset?.chromaKeyColor) {
                    console.log('material switched', assetId)
                    if (!asset?.chromaKeyColor) {
                      console.log('removing chromakey', assetId)
                      //remove chroma key
                      const videoTexture = sceneref.material.uniforms.tex.value;
                      const newMaterial = new MeshBasicMaterial({ map: videoTexture });
                      newMaterial.shadowSide = DoubleSide;
                      sceneref.material.dispose()
                      sceneref.material = newMaterial;
                      sceneref.material.needsUpdate = true;
                    } else {
                      console.log('adding chromakey', asset?.chromaKeyColor)
                      //add chroma key
                      const videoTexture = sceneref.material.map;
                      const color = new Color(asset?.chromaKeyColor);
                      const width = videoTexture.image.videoWidth;
                      const height = videoTexture.image.videoHeight;
                      const chromaKeyMaterial = new ShaderMaterial({
                        uniforms: {
                          tex: { value: videoTexture },
                          keyColor: { value: color },
                          texWidth: { value: width },
                          texHeight: { value: height },
                          similarity: { value: 0.01 },
                          smoothness: { value: 0.18 },
                          spill: { value: 0.1 },
                        },
                        vertexShader: VERTEX_SHADER,
                        fragmentShader: FRAGMENT_SHADER,
                        transparent: true,
                      });
                      chromaKeyMaterial.shadowSide = DoubleSide;
                      sceneref.material.dispose();
                      sceneref.material = chromaKeyMaterial;
                      sceneref.material.needsUpdate = true;
                    }
                  } else {
                    console.log('removing chromakey', assetId)
                    sceneref.material.uniforms.keyColor.value = new Color(asset.chromaKeyColor);
                    sceneref.material.needsUpdate = true;
                  }
                }

                if ('coords' in asset) {
                  Object.keys(asset.coords).forEach((coord) => {
                    let lastCoords = JSON.stringify(lastAsset.coords[coord]);
                    let coords = JSON.stringify(asset.coords[coord]);

                    if (coords !== lastCoords) {
                      sceneref.matrix.identity();
                      console.log('sceneref', sceneref)
                      if (coord === 'p') {
                        console.log('changing postions', asset.coords[coord])
                        sceneref.position.set(asset.coords.p[0], asset.coords.p[1], asset.coords.p[2]);
                      } else if (coord === 'r') {
                        console.log('changing rotation', asset.coords[coord])
                        sceneref.rotation.set(asset.coords.r[0], asset.coords.r[1], asset.coords.r[2]);
                      } else if (coord === 's') {
                        console.log('changing scale', asset.coords[coord])
                        sceneref.scale.set(asset.coords.s, asset.coords.s, asset.coords.s);
                      }
                      console.log('Updating Matrix', asset.coords[coord])
                      sceneref.updateMatrixWorld(true);
                    }
                  })
                }

                if (asset?.volume !== lastAsset?.volume) {
                  console.log('changing volume', assetId)
                  console.log(assetRef)
                  if (!assetRef.muted) {
                    assetRef.volume = asset.volume;
                  }
                }

                if (asset?.linkUrl !== lastAsset?.linkUrl) {
                  if (asset.type === 'model') {
                    //const bbox = sceneref.getObjectByName('boundingBox')
                    //bbox.userData['linkUrl'] = asset.linkUrl;

                    sceneref.traverse((child) => {
                      if (child.isMesh) {
                        child.userData['linkUrl'] = asset.linkUrl;
                      }
                    })
                  } else {
                    sceneref.userData['linkUrl'] = asset.linkUrl;
                  }
                }
              }
            }
          }
        }
      });
    }

    if (currLastCard && lastCardAssets !== assetsString) {
      updateAssets();
    }

    // const linkString = JSON.stringify(currCard.links); // omitting due to eslint warning
    const last = JSON.parse(lastLinks);
    AppLog('last', last)
    AppLog('currCard.links', currCard.links)
    let ids: any = [];
    if (last.length) {
      last.forEach((link, index) => {
        ids.push(link.id);
      })
    }

    const toBeRemoved: any = [];
    anchor.current.group.traverse((ac: any) => {
      if (ids.includes(ac.userData['identifier'])) {
        toBeRemoved.push(ac);
      }
    });

    toBeRemoved.forEach(ac => anchor.current.group.remove(ac));

    const test1 = anchor.current.group.getObjectByName('test1');
    const test2 = anchor.current.group.getObjectByName('test2');

    if (currCard.links.length) {
      AppLog('gotlinks')
      console.log(anchor.current.group)
      if (test1) {
        anchor.current.group.remove(test1);
      }
      if (test2) {
        anchor.current.group.remove(test2);
      }
      makeLinks(currCard.links, .05, -0.45, true);
    } else {
      AppLog('dontgotlinks')
      if (currCard?.currStep === Step.UploadVideo_4) {
        const prelinks: any = []
        if (!test1) {
          prelinks.push({ link: 'www.youtube.com', id: 'test1', type: 'web', text: 'Visit Us' })
        }
        if (!test2) {
          prelinks.push({ link: 'www.facebook.com', id: 'test2', type: 'web', text: 'Visit Us' })
        }
        makeLinks(prelinks);
      } else {
        const baseVideo = anchor.current.group.getObjectByName('baseVideo');
        const baseImage = anchor.current.group.getObjectByName('baseImage');
        if (baseVideo) {
          if (isPlaying(currAssets.current['baseVideo'].ref)) {
            currAssets.current['baseVideo'].ref.pause();
          }
          anchor.current.group.remove(baseVideo);
        }
        if (baseImage) {
          anchor.current.group.remove(baseImage);
        }
      }
    }

    setLastCard(currCardString);
  }, [currCard])

  // useEffect(() => {
  //   if (!isLoading) {
  //     if (currLogo.image) {
  //       let logo = { ...currLogo };
  //       if (id === 'uGFeS2yMTZuLvMrDmGycNE') {
  //         logo.scale = 4;
  //         logo.position = [0, -.25, .1];
  //         logo.rotation = [0, 0, 0];
  //         makeLogo(logo);
  //       } else {
  //         makeLogo(logo);
  //       }
  //     }
  //   }
  //   //makeLogo();
  // }, [currLogo])

  useEffect(() => {
    if (isPreview) {
      contentVisibleRef.current = true;
      if (currCard && Object.keys(currCard).length > 0) {
        // setCard(currCard); // omitting, card is never used
      }
    }
    // Event listener for target found
    const handleTargetFound = () => {
      setTargetFound(true);
      contentVisibleRef.current = true;
    }

    // Event listener for target lost
    const handleTargetLost = () => {
      setTargetFound(false);
      if (!allowUnanchored.current) {
        contentVisibleRef.current = false;
      }
    }

    const handleError = (event) => {
      const errorMessage = event.detail || 'Error loading AR content';
      AppLog("error event fired");
      AppLog(errorMessage);
      setIsLoading(false);
     // setIsError(errorMessage);
    };

    // Add event listeners
    document.addEventListener('targetFound', handleTargetFound);
    document.addEventListener('targetLost', handleTargetLost);
    document.addEventListener('error', handleError);
    // Cleanup event listeners
    return () => {
      document.removeEventListener('targetFound', handleTargetFound);
      document.removeEventListener('targetLost', handleTargetLost);
      document.removeEventListener('error', handleError);
      stopMedia();
    };
  }, []);

  useEffect(() => {
    if (arInstance) {
      if (contentVisibleRef.current || allowUnanchored.current) {
        arInstance.setVisible(true);
        startMedia();
      } else {
        setIsScanning(true);
      }
    }

  }, [isArInstanceStarted])

  useEffect(() => {
    AppLog('contentVisible', contentVisibleRef.current)

    if (isReady) {
      setIsLoading(false);
    }

    const startAr = async () => {
      if (arInstance) {
        AppLog('arinstance', arInstance, anchor.current);

        if (!isArInstanceStarted) {
          setIsStartingAr(true);
          setIsGettingCamera(true);
          //only initialize these once
          try {
            await arInstance._startVideo();
            setIsGettingCamera(false);
          } catch (err) {
            setIsLoading(false);
            setIsGettingCamera(false);
            setIsStartingAr(false);
            console.log('error starting video', err);
            setIsError('error starting video');
            return;
          }
          await arInstance._startAR();
          setIsArInstanceStarted(true);
          if (!isPreview && !isDemo.current) {
            triggerAnalytics('ar_play');
          }
          setIsStartingAr(false);
        }

        arInstance.startProcessing();

        renderer.setAnimationLoop(() => {
          renderer.render(scene, camera);
        });


        needsTapRef.current = needsTap;
      }

      //document.addEventListener('mousemove', onMouseMove, false);
      // Listen for click event to check intersections
      //document.addEventListener('click', onLinkClick, false);

    }

    if (!needsTap && isReady) {
      startAr();
    } else {
      needsTapRef.current = needsTap;
    }

  }, [isReady, needsTap]);

  useEffect(() => {
    if (arInstance) {
      const startvideo = async () => {
        //await arInstance._startVideo();
        setRenderer(arInstance.renderer);
        setScene(arInstance.scene);
        setCamera(arInstance.camera);
        anchor.current = arInstance.addAnchor(0);
        //await arInstance._startAR();
        setProgress(100)
        loadScene();
        //setAnchor(anchor);
      }

      startvideo();
    }
  }, [arInstance]);

  // useEffect(() => {
  //  loadScene();
  // }, [anchor]);

  useEffect(() => {
    if (!sceneRef.current) return;

    if (target && !arInstance) {
      let cameraDistance = 3;
      if (id === 'nPkTzHt6ecXQLRETkTd6FJ') {
        cameraDistance = 8;
      }

      if (allowUnanchored.current) {
        contentVisibleRef.current = true;
      }

      setArInstance(new MindARThree({
        container: sceneRef.current,
        imageTargetSrc: target,
        maxTrack: 1,
        cameraDistance: cameraDistance,
        isVisible: contentVisibleRef.current,
        isPreview: isPreview
      }));
    }
  }, [target]);

  // const getUserCountry = async () => {
  //   return 'United States';
  //   // try {
  //   //   const position: any = await new Promise((resolve, reject) => {
  //   //     navigator.geolocation.getCurrentPosition(resolve, reject);
  //   //   });
  //   //   const { latitude, longitude } = position.coords;
  //   //   const response = await fetch(
  //   //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBknQhZHMDyt-JrrR0Q8YbCT1USfwD9XOI`
  //   //   );
  //   //   const data = await response.json();
  //   //   const country = data.results[0].address_components.find(
  //   //     (component) => component.types.includes('country')
  //   //   ).long_name;
  //   //   return country;
  //   // } catch (error) {
  //   //   console.error('Error getting user country:', error);
  //   //   return null;
  //   // }
  // };

  const triggerAnalytics = async (event, linkUrl = '') => {
    return new Promise(async (resolve, reject) => {
      try {
        const ip = await fetch('https://api.ipify.org?format=json');
        const data = await ip.json();
        const payload = {
          event: event,
          userId: scard?.uid,
          sceneId: scard?._id,
          markerId: smarker?._id,
          linkUrl: linkUrl,
          device: getUserAgent(),
          ip: data.ip,
        }
        const res = await reportAnalytics(payload);
        resolve(res);
      } catch (err) {
        console.log('error', err)
        reject(err)
      }
    })
  }

  const getUserAgent = () => {
    const userAgent = navigator.userAgent;
    let deviceInfo = 'Unknown';
    if (/Android/i.test(userAgent)) {
      deviceInfo = 'Android';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      deviceInfo = 'iOS';
    } else if (/Windows/i.test(userAgent)) {
      deviceInfo = 'Windows';
    } else if (/Mac/i.test(userAgent)) {
      deviceInfo = 'Mac';
    } else if (/Linux/i.test(userAgent)) {
      deviceInfo = 'Linux';
    }
    return deviceInfo;
  }

  // const sceneOpen = async (type, userId, ) => {
  //   const userAgent = navigator.userAgent;
  //   let deviceInfo = 'Unknown';
  //   if (/Android/i.test(userAgent)) {
  //     deviceInfo = 'Android Device';
  //   } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
  //     deviceInfo = 'iOS Device';
  //   } else if (/Windows/i.test(userAgent)) {
  //     deviceInfo = 'Windows PC';
  //   } else if (/Mac/i.test(userAgent)) {
  //     deviceInfo = 'Mac';
  //   } else if (/Linux/i.test(userAgent)) {
  //     deviceInfo = 'Linux PC';
  //   }
  //   let country = 'United States';
  //   dispatch(updateAnalytics({
  //     country: {
  //       [country]: 1
  //     },
  //     device: {
  //       [deviceInfo]: 1,
  //     },
  //     os: {
  //       [navigator.platform]: 1,
  //     }
  //   }, id))
  // }

  function isPlaying(ref: any) {
    return ref.currentTime >= 0 && !ref.paused && !ref.ended && ref.readyState > ref.HAVE_CURRENT_DATA;
  }

  useEffect(() => {
    if (targetFound && !needsTap) {
      //isScanningRef.current = false;
      setIsScanning(false);
      console.log ('target found')
      startMedia();

      if (!isPreview && !isDemo.current) {
        triggerAnalytics('ar_scan');
      }
    }

    if (!targetFound && !needsTap) {
      if (allowUnanchored.current) {
        contentVisibleRef.current = true;
        arInstance?.repositionCamera();
        //isScanningRef.current = true;

      } else {
      //isScanningRef.current = true;
      contentVisibleRef.current = false;
      stopMedia();
      }
      setIsScanning(true);
    }
  }, [targetFound]);

  // omitting due to eslint warning
  // function makeYoutube(ytid, anchor) {
  //   const div = document.createElement('div');
  //   div.style.width = '480px';
  //   div.style.height = '360px';
  //   div.style.backgroundColor = '#000';
  //   const iframe = document.createElement('iframe');
  //   iframe.src = ['https://www.youtube.com/embed/', ytid, '?rel=0&autoplay=1&mute=1'].join('');
  //   iframe.style.width = '480px';
  //   iframe.style.height = '360px'
  //   iframe.style.border = '0px';
  //   iframe.allowFullscreen = true;
  //   div.appendChild(iframe);
  //   const cssObject = new CSS3DObject(div);
  //   cssObject.position.set(0, 0, 0);
  //   anchor.group.add(cssObject);
  //   const cssRenderer = new CSS3DRenderer();
  //   cssRenderer.setSize(window.innerWidth, window.innerHeight);
  //   cssRenderer.domElement.style.position = 'absolute';
  //   cssRenderer.domElement.style.top = 0;
  //   document.body.appendChild(cssRenderer.domElement);
  // }

  // omitting code block due to eslint warning
  // function make3DLink(link, index, total, xoffset) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const spacing = 0.2;
  //       let fnButton: any = null;
  //       if ('icon' in link && link.icon && 'text' in link && link.text) {
  //         const font: any = await loadFont('/fonts/droid_sans_bold.typeface.json');
  //         const textGeometry = new TextGeometry(link.text, {
  //           font: font,
  //           size: .05,
  //           height: .01,
  //           curveSegments: 12,
  //           bevelEnabled: false,
  //           bevelThickness: 0.01,
  //           bevelSize: 0.01,
  //           bevelOffset: 0,
  //           bevelSegments: 5
  //         });

  //         const material = new MeshPhongMaterial({ color: 0xffffff });
  //         fnButton = new Mesh(textGeometry, material);
  //       } else {
  //         reject(false);
  //       }

  //       // Calculate the starting position
  //       let totalWidth = (total - 1) * spacing;
  //       let startPosition = -totalWidth / 2;

  //       fnButton.position.set(startPosition + index * spacing, xoffset, .1);
  //       anchor.group.add(fnButton);
  //       resolve(fnButton);
  //     } catch (err) {
  //       reject(false);
  //     }
  //   });
  // }

  function makeLinks(links, size = .05, xoffset = -0.45, reset = false) {
    // const raycaster = new Raycaster();
    // const mouse = new Vector2();
    let btobjs: any = [];
    let btlinks: any = [];
    let btIds: any = [];

    return new Promise(async (resolve, reject) => {
      try {
        await Promise.all(links.map(async (link, i) => {
          const res: any = await makeLink(link, i, links.length, size, xoffset);
          if (res.length) {
            currLinks.current[link.id] = {
              ref: res[0],
              link: res[1],
              id: res[2],
              type: link.type
            };

            btobjs.push(res[0]);
            btlinks.push(res[1]);
            btIds.push(res[2]);
          }
        }))

        setLastLinks(JSON.stringify(links));

        resolve(true)
      } catch (err) {
        resolve(false)
      }
    })
  }

  function makeLink(link, index, total, size, xoffset) {
    const spacing = 0.6;
    return new Promise(async (resolve, reject) => {
      try {
        let glburl = '/media/glb/bigbtn-orange.glb';
        let parsed = parseLink(link);
        let svgUrl = parsed[0];
        let fnlink = parsed[1];
        console.log('link', link)
        //AppLog('anchor.group', anchor.group)
        const foundExisting = anchor.current.group.getObjectByName(id);
        if (foundExisting) {
          anchor.current.group.remove(foundExisting);
        }

        let totalWidth = (total * size) + ((total - 1) * spacing);
        let startPosition = -totalWidth / 2;
        let thisoffset = (startPosition + (index * size) + (index * spacing));
        if (link?.yoffset) {
          thisoffset = link?.yoffset;
        }
        if (link.type === 'image') {
          const btTexture: any = await loadTexture(glburl);
          btTexture.colorSpace = SRGBColorSpace;
          btTexture.encoding = sRGBEncoding;
          const ratio = btTexture.image.height / btTexture.image.width;
          const button = new PlaneGeometry(size, size * ratio);

          const btMaterial = new MeshBasicMaterial({
            map: btTexture,
            transparent: true, // Ensure the material respects the texture's transparency
            side: DoubleSide,
          });

          let fnButton = new Mesh(button, btMaterial);
          fnButton.castShadow = true;
          fnButton.userData['linkUrl'] = fnlink;
          fnButton.userData['identifier'] = link.id;
          fnButton.position.set(thisoffset, xoffset, .03);
          anchor.current.group.add(fnButton);
          fnButton.name = link.id;
          resolve([fnButton, fnlink, link.id]);
        } else {
          const btmodel: any = await loadModel(glburl);

          btmodel.scene.traverse(function (child) {
            if (child.isMesh) {
              child.castShadow = true;
              child.userData['linkUrl'] = fnlink;
            }
          });

          //AppLog('btmodel', btmodel)
          let fnButton = btmodel.scene;
          fnButton.userData['identifier'] = link.id;
          fnButton.scale.set(.06, .06, .06)
          fnButton.position.set(thisoffset, xoffset, .03);
          fnButton.rotation.set(-.2, 0, 0);
          console.log(link.id)
          fnButton.name = link.id;

          const svgData: any = await loadSvg(svgUrl);
          console.log('svgData', svgData)
          const paths = svgData.paths;

          const material = new MeshBasicMaterial({
            color: 0xffffff,  // Customize color
            side: DoubleSide,
            depthWrite: false
          });

          paths.forEach((path) => {
            const shapes = SVGLoader.createShapes(path);
            shapes.forEach((shape) => {
              const extrudeSettings = {
                depth: 1,
                bevelEnabled: true,
                bevelSegments: 2,
                steps: 2,
                bevelSize: 0.05,
                bevelThickness: 0.05
              };

              const geometry = new ExtrudeGeometry(shape, extrudeSettings);
              const mesh = new Mesh(geometry, material);
              mesh.castShadow = true;
              mesh.position.set(-4, .75, 1.5); // Position it slightly above the button
              mesh.scale.set(0.1, -0.1, 0.1); // Adjust size
              fnButton.add(mesh); // Add the extruded SVG to the button
            });
          });

          const font: any = await loadFont('');
          const textGeometry = new TextGeometry(link?.text || 'Click Here', {
            font: font,
            size: 1, // Adjust size
            height: 1, // Depth of the text
            curveSegments: 12,
            bevelEnabled: true,
            bevelThickness: 0.01,
            bevelSize: 0.01,
            bevelOffset: 0,
            bevelSegments: 5
          });

          textGeometry.computeBoundingBox(); // Calculate the bounds of the text
          const textWidth = textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x;
          const textMesh = new Mesh(textGeometry, material);
          textMesh.castShadow = true; // Optional
          textMesh.position.set((-0.6 * textWidth) + 1, -.5, .4); // Position it slightly above the button
          fnButton.add(textMesh);
          anchor.current.group.add(fnButton);
          resolve([fnButton, fnlink, link.id]);
        }
      } catch (err) {
        reject(false);
      }
    });
  }

  function makeDynamicButton(buttons, index, total, size, xoffset) {
    const spacing = 0.3; // Adjust for the spacing between buttons

    return new Promise((resolve, reject) => {
      try {
        // Determine button position based on total number of buttons
        let totalWidth = (total * size) + ((total - 1) * spacing);
        let startPosition = -totalWidth / 2;
        let thisoffset = (startPosition + (index * size) + (index * spacing));

        // Create a rounded, 3D button shape
        const buttonShape = new Shape();
        const radius = 0.15;
        const width = size;
        const height = size / 3;

        // Create a rounded rectangle shape
        buttonShape.moveTo(0, radius);
        buttonShape.lineTo(0, height - radius);
        buttonShape.quadraticCurveTo(0, height, radius, height);
        buttonShape.lineTo(width - radius, height);
        buttonShape.quadraticCurveTo(width, height, width, height - radius);
        buttonShape.lineTo(width, radius);
        buttonShape.quadraticCurveTo(width, 0, width - radius, 0);
        buttonShape.lineTo(radius, 0);
        buttonShape.quadraticCurveTo(0, 0, 0, radius);

        // Extrude geometry to give it depth
        const extrudeSettings = {
          depth: 0.05,
          bevelEnabled: true,
          bevelThickness: 0.02,
          bevelSize: 0.05,
          bevelSegments: 5
        };

        const buttonGeometry = new ExtrudeGeometry(buttonShape, extrudeSettings);

        // Create a glossy material
        const buttonMaterial = new MeshPhongMaterial({
          color: buttons.color,  // Dynamic color
          shininess: 100,        // Glossy finish
          specular: 0xffffff     // Light reflection for gloss
        });

        // Create the button mesh
        const buttonMesh = new Mesh(buttonGeometry, buttonMaterial);
        buttonMesh.castShadow = true;
        buttonMesh.position.set(thisoffset, xoffset, 0.03);
        buttonMesh.userData['linkUrl'] = buttons.link;
        buttonMesh.name = buttons.id;

        // Add the button mesh to the scene
        anchor.current.group.add(buttonMesh);

        // Create dynamic text for the button
        const textAsset = {
          asset: {
            content: buttons.text,  // Dynamic button text
            color: '#ffffff',       // Default text color
            coords: {
              p: [thisoffset + width / 2, xoffset + height / 2, 0.08],  // Text position
              s: size * 0.1,  // Adjust text size
              r: [0, 0, 0]    // Text rotation
            },
            extrude: 0.05           // Extrude depth for 3D text
          },
          ref: buttons.fontRef      // Font reference for the text
        };

        makeText(textAsset, buttons.id + "_text");
        resolve(buttonMesh);
      } catch (err) {
        reject(false);
      }
    });
  }

  function makeText(asset, id) {
    console.log(makeDynamicButton) //hiding eslint warning

    console.log("makeText", asset)
    if (!arInstance) return; // Ensure AR instance is available

    if (!asset?.asset?.content) return; // Ensure text content is available
    //AppLog('text', text)
    // Load the font (Make sure you have the path to a font in JSON format)
    const textGeometry = new TextGeometry(asset.asset.content, {
      font: asset.ref,
      size: asset.asset.coords.s / 10, // Adjust size
      height: asset.asset.extrude / 20, // Depth of the text
      curveSegments: 12,
      bevelEnabled: true,
      bevelThickness: 0.01,
      bevelSize: 0.01,
      bevelOffset: 0,
      bevelSegments: 5
    });

    textGeometry.computeBoundingBox(); // Calculate the bounds of the text
    const textWidth = textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x;
    const textMaterial = new MeshStandardMaterial({ color: asset.asset.color }); // Customize material as needed

    const textMesh = new Mesh(textGeometry, textMaterial);
    textMesh.position.set(asset.asset.coords.p[0] - (0.5 * textWidth), asset.asset.coords.p[1], asset.asset.coords.p[2]);
    textMesh.rotation.set(asset.asset.coords.r[0], asset.asset.coords.r[1], asset.asset.coords.r[2]);

    textMesh.castShadow = true; // Optional
    textMesh.name = id;
    // const foundText = anchor.group.getObjectByName('userText');
    const foundUserLogo = anchor.current.group.getObjectByName('userImage');

    if (foundUserLogo) {
      foundUserLogo.visible = false;
    }

    const existing = anchor.current.group.getObjectByName(id);
    if (existing) {
      anchor.current.group.remove(existing);
    }

    anchor.current.group.add(textMesh);
  }

  function makeLogo(asset: any, id = 'baseImage') {
    console.log("makeLogo", asset)
    if (arInstance) {

      //this ensures the image doesn't look washed out
      asset.ref.colorSpace = SRGBColorSpace;
      asset.ref.encoding = sRGBEncoding;

      const logoMaterial = new MeshStandardMaterial({
        map: asset.ref,
        transparent: true, // Ensure the material respects the texture's transparency
        shadowSide: DoubleSide,
        side: DoubleSide,
      });

      if (asset?.normalmap) {
        // AppLog('normalmap', asset?.normalmap)
        const texture = new TextureLoader().load(asset.normalmap);
        texture.colorSpace = SRGBColorSpace;
        texture.encoding = sRGBEncoding;
        logoMaterial.alphaMap = texture;
        logoMaterial.alphaTest = .05;
        logoMaterial.bumpMap = texture;
        logoMaterial.bumpScale = 80;
        //logoMaterial.normalMap = logo.normalmap;
        //logoMaterial.normalScale = new Vector2(1, 1);
      }

      const logoratio = asset.ref.image.height / asset.ref.image.width;
      //AppLog('logoratio', logoratio);
      let logoGeometry = new PlaneGeometry(.2, .2 * logoratio);
      if (logoratio < 1) {
        logoGeometry = new PlaneGeometry(.2 / logoratio, .2);
        //scale = (scale * logoratio) * 6;
      }

      const logoPlane = new Mesh(logoGeometry, logoMaterial);

      logoPlane.scale.set(asset.asset.coords.s, asset.asset.coords.s, asset.asset.coords.s);
      logoPlane.position.set(asset.asset.coords.p[0], asset.asset.coords.p[1], asset.asset.coords.p[2]);
      logoPlane.rotation.set(asset.asset.coords.r[0], asset.asset.coords.r[1], asset.asset.coords.r[2]);


      logoPlane.name = id;
      logoPlane.castShadow = true;

      const foundBase = anchor.current.group.getObjectByName('baseImage');
      if (foundBase) {
        anchor.current.group.remove(foundBase);
      }

      const foundExisting = anchor.current.group.getObjectByName(id);
      if (foundExisting) {
        anchor.current.group.remove(foundExisting);
      }
      if (asset?.asset?.linkUrl) {
        logoPlane.userData['linkUrl'] = asset?.asset?.linkUrl;
      }

      anchor.current.group.add(logoPlane);
      return logoPlane;
      //AppLog('anchor.group', anchor.group)
      //currMeshIds.logo = logoPlane.id;
      //setMeshIds(currMeshIds);
    }
    return null;
  }

  function make3Dmodel(asset: any, id = 'baseModel') {
    if (arInstance) {
      // let currMeshIds = { ...meshIds };
      let mixer: any;
      const model = asset.ref;
      const animations = asset.animations;
      /*
      const boundingBox = new Box3().setFromObject(model);
      // Get the size of the bounding box
      const boxSize = new Vector3();
      boundingBox.getSize(boxSize);

      // Create a box geometry that matches the bounding box
      const boxGeometry = new BoxGeometry(boxSize.x, boxSize.y, boxSize.z);

      // Create a material (you can make it invisible)
      const boxMaterial = new MeshBasicMaterial({ color: 0x00ff00, visible: false }); // Set visible to true for debugging

      // Create the mesh that represents the bounding box
      const boxMesh = new Mesh(boxGeometry, boxMaterial);

      // Set the position of the boxMesh to match the bounding box center
      const boxCenter = new Vector3();
      boundingBox.getCenter(boxCenter);
      boxCenter.sub(model.position);
      boxMesh.position.copy(boxCenter);
      boxMesh.name = 'boundingBox';
      model.add(boxMesh);
      */
      model.traverse(function (child) {
        if (child.isMesh) {
          child.castShadow = true;
          if (asset?.asset?.linkUrl) {
            child.userData['linkUrl'] = asset.asset.linkUrl;
          }
        }
      })
      /*
      if (asset?.asset?.linkUrl) {
        boxMesh.userData['linkUrl'] = asset.asset.linkUrl;
      }
      */
      if (animations && animations.length > 0) {
        mixer = new AnimationMixer(model);
        const action = mixer.clipAction(animations[0]); // Change index as needed
        action.play();
      }

      model.scale.set(asset.asset.coords.s, asset.asset.coords.s, asset.asset.coords.s);
      model.position.set(asset.asset.coords.p[0], asset.asset.coords.p[1], asset.asset.coords.p[2]);
      model.rotation.set(asset.asset.coords.r[0], asset.asset.coords.r[1], asset.asset.coords.r[2]);
      model.name = id;

      const foundBase = anchor.current.group.getObjectByName('baseModel');
      if (foundBase) {
        anchor.current.group.remove(foundBase);
      }

      const foundExisting = anchor.current.group.getObjectByName(id);
      if (foundExisting) {
        anchor.current.group.remove(foundExisting);
      }

      anchor.current.group.add(model);

      const clock = new Clock();
      const animate = () => {
        const delta = clock.getDelta();
        if (mixer) {
          mixer.update(delta);
        }
        //arInstance.renderer.render(arInstance.scene, arInstance.camera);
        window.requestAnimationFrame(animate);
      };

      animate();
      return model;
    }
    return null;
  }

  function loadFont(url = '') {
    if (!url) {
      url = '/fonts/droid_sans_bold.typeface.json';
    }
    return new Promise((resolve, reject) => {
      const loader = new FontLoader();
      loader.load(url, resolve, undefined, reject);
    });
  }

  function loadSvg(url) {
    return new Promise((resolve, reject) => {
      const loader = new SVGLoader();
      loader.load(url, resolve, undefined, reject);
    });
  }

  function loadTexture(url) {
    return new Promise((resolve, reject) => {
      const loader = new TextureLoader();
      loader.load(url, resolve, undefined, reject);
    });
  }

  function loadModel(src) {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'https://cdn.jsdelivr.net/npm/three@0.139.2/examples/js/libs/draco/' );
    loader.setDRACOLoader( dracoLoader );
    return new Promise((resolve, reject) => {
      loader.load(src, (gltf) => {
        resolve(gltf);
        dracoLoader.dispose(); // Dispose after decoding
      }, undefined, (error) => {
        reject(error);
        dracoLoader.dispose();
      });
    });
  }

  function loadAudio(asset: any) {
    return new Promise((resolve, reject) => {
      AppLog('loadAudio.src', asset.url)
      let aref = document.createElement('audio');
      asset.assetId = id;

      const cleanup = () => {
        aref.removeEventListener('loadeddata', onLoaded);
        aref.removeEventListener('error', onError);
      };

      const onLoaded = async () => {
        cleanup();
        const canplay = await checkAudio(aref);
        resolve({ ref: aref, canplay: canplay });
        resolve(true);
      };

      const onError = (e) => {
        cleanup();
        reject(new Error(`Failed to load audio: ${e.message}`));
      };

      aref.addEventListener('loadeddata', onLoaded);
      aref.addEventListener('error', onError);
      aref.loop = asset.loop;
      if (isPreview || !asset.volume) {
        aref.muted = true;
      } else {
        aref.muted = false;
      }
      aref.autoplay = false;
      aref.preload = 'auto';
      aref.volume = asset.volume;
      aref.crossOrigin = 'anonymous';
      aref.controls = false;
      aref.src = asset.url;
      aref.load();
      audioRefs.current.push(aref);
    });
  }

  function loadVideo(asset: any) {
    return new Promise((resolve, reject) => {
      let vref = document.createElement('video');
      vref.id = asset.assetId;
      AppLog('loadVideo.src', asset.url)
      const cleanup = () => {
        vref.removeEventListener('loadeddata', onLoaded);
        vref.removeEventListener('error', onError);
      };

      const onLoaded = async () => {
        cleanup();
        const canplay = await checkVideo(vref);
        resolve({ ref: vref, canplay: canplay });
      };

      const onError = (e) => {
        cleanup();
        reject(new Error(`Failed to load video: ${e.message}`));
      };

      vref.addEventListener('loadeddata', onLoaded);
      vref.addEventListener('error', onError);
      vref.src = asset.url;
      vref.loop = asset.loop;
      if (isPreview || !asset.volume) {
        vref.muted = true;
      } else {
        vref.muted = false;
      }
      vref.volume = asset.volume;
      vref.autoplay = false;
      vref.playsInline = true;
      vref.crossOrigin = 'anonymous';
      vref.preload = 'auto';
      //video.controls = true;
      vref.load();
    });
  }

  function checkVideo(vref: any) {
    return new Promise((resolve, reject) => {
      const playPromise = vref.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          console.log('videoready', vref.readyState)
          vref.pause();

          resolve(true);
          AppLog("Autoplay succeeded");
        }).catch(error => {
          resolve(false);
          AppLog(error)
          // Autoplay was prevented
          AppLog("Autoplay was prevented. Showing fallback UI.");
          //showFallbackUI();
        });
      } else {
        resolve(false);
        // Browser does not support the play promise, show fallback UI
        //showFallbackUI();
      }
    })
  }

  function resumeVideo(vref: HTMLVideoElement) {
    // Ensure video is ready before resuming
    if (vref.readyState >= 2) { // Ready state 2 = HAVE_CURRENT_DATA
      vref.play().catch(error => {
        AppLog(`Error resuming ${vref.id}:`, error);
      });
    } else {
      console.log(`Video ${vref.id} is not ready to play.`);
      vref.load(); // Reload the video if it’s in an inconsistent state
      vref.play().catch(error => {
        AppLog(`Error playing ${vref.id} after reload:`, error);
      });
    }
  }

  function checkAudio(aref: any) {
    return new Promise((resolve, reject) => {
      const playPromise = aref.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          aref.pause();
          resolve(true);
          AppLog("Autoplay succeeded");
        }).catch(error => {
          resolve(false);
          AppLog(error)
          // Autoplay was prevented
          AppLog("Autoplay was prevented. Showing fallback UI.");
          //showFallbackUI();
        });
      } else {
        resolve(false);
        // Browser does not support the play promise, show fallback UI
        //showFallbackUI();
      }
    })
  }

  function makeVideo(asset: any, id = 'baseVideo') {
    console.log('makeVideo', asset)
    if (arInstance) {
      // let currMeshIds = { ...meshIds }; // omitting due to eslint warning
      const videoElement = asset.ref;
      const videoTexture = new VideoTexture(videoElement);
      videoTexture.minFilter = LinearFilter;
      videoTexture.magFilter = LinearFilter;
      videoTexture.format = RGBAFormat;

      // these might not apply to video since format = RGBAFormat
      videoTexture.colorSpace = SRGBColorSpace;
      videoTexture.encoding = sRGBEncoding;

      AppLog('scale', asset.asset.coords.s)
      let scale = asset.asset.coords.s;
      let relscale = scale * .6
      const width = videoElement.videoWidth;
      const height = videoElement.videoHeight;
      const videoAspectRatio = width / height;
      const planeWidth = videoAspectRatio * relscale;
      const planeHeight = relscale;
      const planeAspectRatio = planeWidth / planeHeight;

      let material = new MeshBasicMaterial({ map: videoTexture });
      if (asset.asset.chromaKeyColor) {
        const color = new Color(asset.asset.chromaKeyColor)
        material = new ShaderMaterial(
          {
            uniforms: {
              tex: {
                value: videoTexture,
              },
              keyColor: { value: color },
              texWidth: { value: width },
              texHeight: { value: height },
              similarity: { value: 0.01 },
              smoothness: { value: 0.18 },
              spill: { value: 0.1 },

            },
            vertexShader: VERTEX_SHADER,
            fragmentShader: FRAGMENT_SHADER,
            transparent: true,
          })
      }
      material.shadowSide = DoubleSide;

      // let vwidth = 1; // omitting due to eslint warning
      // let vheight = 1 / videoAspectRatio; // omitting due to eslint warning
      // if (videoAspectRatio <= 1) {
      //   vwidth = 1;
      //   vheight = 1 * videoAspectRatio;
      // }
      AppLog('videoAspectRatio', videoAspectRatio)
      let textureOffsetX = 0;
      let textureOffsetY = 0;
      AppLog('videoAspectRatios')
      AppLog(videoAspectRatio)
      AppLog(planeAspectRatio)
      // Calculate the aspect ratio
      if (videoAspectRatio > planeAspectRatio) {
        // Video is more portrait than the plane
        const repeatX = planeAspectRatio / videoAspectRatio;
        videoTexture.repeat.set(repeatX, 1);
        textureOffsetX = (1 - repeatX) / 2; // Center horizontally
      } else {
        // Video is more landscape than the plane
        const repeatY = videoAspectRatio / planeAspectRatio;
        videoTexture.repeat.set(1, repeatY);
        textureOffsetY = (1 - repeatY) / 2; // Center vertically
      }

      videoTexture.offset.set(textureOffsetX, textureOffsetY);
      const vidplane = new PlaneGeometry(planeWidth, planeHeight);
      let mesh = new Mesh(vidplane, material);
      mesh.scale.set(scale, scale, scale);
      mesh.position.set(asset.asset.coords.p[0], asset.asset.coords.p[1], asset.asset.coords.p[2]);
      mesh.rotation.set(asset.asset.coords.r[0], asset.asset.coords.r[1], asset.asset.coords.r[2]);
      mesh.name = id;
      mesh.castShadow = true;
      const baseVideo = anchor.current.group.getObjectByName('baseVideo');
      if (baseVideo) {
        if (isPlaying(currAssets.current['baseVideo'].ref)) {
          currAssets.current['baseVideo'].ref.pause();
        }
        anchor.current.group.remove(baseVideo);
        setIsMuted(true);
      }

      const existing = anchor.current.group.getObjectByName(id);
      if (existing) {
        //console.log('existing', existing)
        //console.log('currAssets.current[id]', currAssets.current[id])
        if (isPlaying(currAssets.current[id].ref)) {
          currAssets.current[id].ref.pause();
        }
        anchor.current.group.remove(existing);
        setIsMuted(true);
      }
      //const userVideo = anchor.group.getObjectByName('userVideo');
      //if (userVideo) {
      //  anchor.group.remove(userVideo);
      //}
      anchor.current.group.add(mesh);
      return mesh;
      //currMeshIds.video = mesh.id;
      //setMeshIds(currMeshIds);
      //AppLog('vidmeshid', vidmeshid)
      //AppLog('anchor.group', anchor.group)
    }
  }

  function addLights() {
    if (arInstance) {
      const ambientLight = new AmbientLight(0xffffff, .4); // Ambient light
      const directionalLight = new DirectionalLight(0xffffff, .6); // Directional light
      directionalLight.position.set(0, 3, 6);
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.width = 1024
      directionalLight.shadow.mapSize.height = 1024
      directionalLight.shadow.camera.near = 0.001
      directionalLight.shadow.camera.far = 100
      // Add lights to the scene
      arInstance.scene.add(ambientLight);
      arInstance.scene.add(directionalLight);
    }
  }

  async function loadScene() {
    if (arInstance) {
      addLights();

      // let promises = [];
      Object.entries(currAssets.current).forEach(([key, value]: any) => {
        if (value.type === 'video') {
          let mesh = makeVideo(value, key)
          currAssets.current[key]['mesh'] = mesh;
        } else if (value.type === 'image') {
          let mesh = makeLogo(value, key)
          currAssets.current[key]['mesh'] = mesh;
        } else if (value.type === 'model') {
          let mesh = make3Dmodel(value, key)
          currAssets.current[key]['mesh'] = mesh;
        }
      })

      if (isPreview && currCard?.currStep === Step.UploadVideo_4 && currCard?.links.length === 0) {
        makeLinks([
          { link: 'youtube.com', id: 'test1', type: 'web', text: 'Visit Us' },
          { link: 'facebook.com', id: 'test2', type: 'web', text: 'Visit Us' }
        ]);
      } else {
        makeLinks(scard?.links);
      }
      setIsReady(true);
    }
  }

  function parseLink(link) {
    const incl = (str) => link.link.toLowerCase().includes(str)

    const linkpre = "/media/buttonsvgs/";
    let linkimg = linkpre + "link.svg"
    let fnlink = link.link;

    if (link.type === "vcard") {
      linkimg = linkpre + "vcard.svg";
    } else if (link.type === "email") {
      linkimg = linkpre + "email.svg";
      fnlink = `mailto:${link.link}`;
    } else if (link.type === "sms") {
      linkimg = linkpre + "text.svg";
      fnlink = `sms:${link.link}`;
    } else if (link.type === "phone") {
      linkimg = linkpre + "call.svg";
      fnlink = `tel:${link.link}`;
    } else if (link.type === "web") {
      if (!link.link.includes("://")) {
        fnlink = `https://${link.link}`;
      }

      if (incl("youtube.com")) {
        linkimg = linkpre + "youtube.svg";
      } else if (incl("instagram.com")) {
        linkimg = linkpre + (link?.isSocial ? "instagram-color.svg" : "instagram.svg");
      } else if (incl("facebook.com")) {
        linkimg = linkpre + (link?.isSocial ? "facebook-color.svg" : "facebook.svg");
      } else if (incl("tiktok.com")) {
        linkimg = linkpre + (link?.isSocial ? "tiktok.svg" : "tiktok-black.svg");
      } else if (incl("linkedin.com")) {
        linkimg = linkpre + "linkedin.svg";
      } else if (incl("spotify.com")) {
        linkimg = linkpre + "spotify.svg";
      } else if (incl("pinterest.com")) {
        linkimg = linkpre + "pinterest.svg";
      } else if (incl("twitter.com") || incl("t.co") || incl("x.co")) { //keep at the bottom of the 'if' block
        linkimg = linkpre + "twitter-x.svg";
      } else {
        linkimg = linkpre + "link.svg";
      }
    } else if (link.type === "image") {
      linkimg = link.imageUrl;
      fnlink = link.link;
    }
    return [linkimg, fnlink];
  }

  async function loadContent(card) {
    try {
      setProgress(0);
      console.log('card', card)
      setSocialLinks(card?.socials || []);
      if (isPreview) {
        currMarker.current = {
          markerId: 'baseMarker',
          productId: '',
          name: 'Initial Marker',
          mindUrl: begMindUrl,
          imageUrl: '/media/ar/qrcode.svg',
          fullimageUrl: '/media/ar/qrcode.svg',
          aspect: 1,
          background: '#FFFFFF',
          qr: {
            visible: false,
            url: '',
            image: '/media/ar/qrcode.svg',
            color: '#000000',
            position: [0, 0],
            scale: 1,
          }
        };
        setMarkerImg('/media/ar/qrcode.svg');
      } else {
        if (isDemo.current) {
          currMarker.current = {
            markerId: 'demoMarker',
            productId: '',
            name: 'Demo Marker',
            mindUrl: card?.mindUrl,
            imageUrl: card?.qrImg,
            fullimageUrl: card?.qrImg,
            aspect: 1,
            background: '#FFFFFF',
            qr: {
              visible: false,
              url: '',
              image: card?.qrImg,
              color: '#000000',
              position: [0, 0],
              scale: 1,
            }
          };
          setMarkerImg(card?.qrImg);
        } else {
          triggerAnalytics('qr_scan');
          setMarkerImg(currMarker.current?.compiledImageUrl);
        }

      }

      if (card?.startVisible || isPreview) {
        allowUnanchored.current = true;
        contentVisibleRef.current = true;
      }

      let thisneedsTap = false;

      let promises: any = [];
      let progress = 0;
      // Function to update progress
      const updateProgress = () => {
        progress++;
        setProgress((progress / promises.length) * 100);
        console.log('progress', progress);
      };

      const addAssetWithProgress = async (asset, assetId) => {
        await addAsset(asset, assetId);
        updateProgress();
      };

      if (Object.keys(card?.sceneAssets).length > 0) {
        Object.keys(card?.sceneAssets).forEach(async (assetId) => {
          promises.push(addAsset(card?.sceneAssets[assetId], assetId));
        })
      } else {
        if (isPreview) {
          let previewVideo = {
            type: 'video',
            url: begVideoUrl,
            thumbUrl: '',
            assetId: 'baseVideo',
            format: 'mp4',
            width: 0,
            height: 0,
            scaleToMarker: false,
            fillMarker: false,
            chromaKeyColor: '',
            linkUrl: '',
            loop: true,
            volume: 1,
            coords: {
              p: [0, -.03, 0],
              r: [0, 0, 0],
              s: 1,
            }
          }

          promises.push(addAssetWithProgress(previewVideo, 'baseVideo'));

          let previewLogo = {
            type: 'image',
            url: begLogoUrl,
            assetId: 'baseImage',
            aspect: 1,
            format: 'png',
            width: 1920,
            height: 1080,
            isVector: false,
            scaleToMarker: false,
            fillMarker: false,
            linkUrl: '',
            coords: {
              p: [0, .4, .1],
              r: [.3, 0, 0],
              s: 1,
            }
          }

          promises.push(addAssetWithProgress(previewLogo, 'baseImage'));
        }
      }
      await Promise.all(promises);

      Object.values(currAssets.current).forEach((value: any) => {
        if (value.type === 'video' || value.type === 'audio') {
          if (!value.canplay) {
            thisneedsTap = true;
          }
        }
      })

      if (!isPreview && !hasOrientationPermission) {
        thisneedsTap = true;
      }

      if (card?.links) {
        // setCurrLinks(card.links);
      }

      // setCurrStep(card.currStep || 0);
      currMarker.current['mindUrl'] = parseUrl(currMarker.current.mindUrl)
      console.log('mindurl', currMarker.current.mindUrl)

      const currCardString = JSON.stringify(currCard);
      setLastCard(currCardString);
      const content = await fetch(currMarker.current.mindUrl);
      const buffer = await content.arrayBuffer();
      setTarget(buffer);
      setNeedsTap(thisneedsTap);
    } catch (err) {
      AppLog(err)
    }
  }

  const handleTap = () => {
    let hasAudio = startMedia();
    if (allowUnanchored.current){
      contentVisibleRef.current = true;
      //isScanningRef.current = true;
      setIsScanning(true);
    }

      stopMedia();


    //if (!hasAudio) {
      setNeedsTap(false);
    //}
  }

  const stopMedia = () => {
    Object.values(currAssets.current).forEach((asset: any) => {
      if (asset.type === 'video' || asset.type === 'audio') {
        if (isPlaying(asset.ref)) {
          asset.ref.pause();
        }
      }
    })
  }

  const startMedia = () => {
    let hasAudio = false;

    Object.values(currAssets.current).forEach((asset: any) => {
      if (asset.type === 'video' || asset.type === 'audio') {
        hasAudio = true;

        // Check for the asset reference
        console.log('Checking asset', asset);

        if (asset.ref && !isPlaying(asset.ref)) {
          console.log('Attempting to play asset', asset.ref);

          // Attach event listeners to monitor playback state
          asset.ref.addEventListener('play', () => {
            console.log(`Video ${asset.ref.src} started playing`);
          });
          asset.ref.addEventListener('pause', () => {
            console.log(`Video ${asset.ref.src} paused`);
          });
          asset.ref.addEventListener('ended', () => {
            console.log(`Video ${asset.ref.src} ended`);
          });
          asset.ref.addEventListener('error', (e) => {
            console.error(`Video ${asset.ref.src} encountered an error:`, e);
          });

          // Attempt to play and catch potential errors
          resumeVideo(asset.ref)
        } else {
          console.log(`Video ${asset.ref.src} is already playing`);
        }
      }
    });

    return hasAudio;
  }

  const handleViewExp = () => {
    contentVisibleRef.current = true;

    //arInstance?.repositionCamera();
    //arInstance?.repositionCamera(true);
  }

  //CSS Styles

  const getContainerStyle = () => {
    let style: any = { width: "100%", height: "100%", position: "relative", background: "transparent", overflow: 'hidden' }

    if(isPreview) {
      style = { ...style, borderRadius: '16px', boxShadow: '0px 2px 10px 4px #AAAAAA' }
    }

    return style
  }

  return (
    <>
      {!isPreview && (<div style={{ width: "100%", height: "100%", position: "fixed", background: "black", zIndex: -100 }}></div>)}
      <div style={getContainerStyle()}>
        {!isPreview && (
          <div style={{ width: "100%", height: "100%", position: "absolute", background: "transparent", zIndex: 98 }}>
            <StatusOverlay
              markerImg={markerImg}
              isLoading={isLoading}
              isScanning={isScanning}
              isProcessing={isProcessing}
              isPublished={isPublished}
              isDemo={isDemo.current}
              needsTap={needsTap}
              isError={isError}
              contentVisible={contentVisibleRef.current}
              allowUnanchored={allowUnanchored.current}
              progress={progress}
              hasTapped={handleTap}
              viewExp={handleViewExp}
            />
          </div>)}
        {isPreview && (
          <div onClick={() => setIsMuted(!isMuted)} style={{ position: 'absolute', width: '50px', height: '50px', borderRadius: '50%', background: 'black', right: '30px', bottom: '30px', zIndex: 99 }}>
            <i style={{ color: 'white', fontSize: '25px', margin: '12px' }} className={isMuted ? 'fas fa-volume-xmark fa-2xl' : 'fas fa-volume-high fa-2xl'}></i>
          </div>
        )}
        {!needsTap && isReady && !isStartingAr && (<div style={{ position: "absolute", bottom: '25px', left: '25px', width: '40px', zIndex: 99 }} >
          {socialLinks?.map((link, i) => {
            let [linkimg, fnlink] = parseLink({ ...link, isSocial: true });
            return (
              <a key={i} href={fnlink} target="_blank" rel="noreferrer">
                <img src={linkimg} alt='' style={{ width: '100%', height: '40px', marginTop: '10px' }} />
              </a>
            )
          })}
        </div>)}
        {isStartingAr && !isPreview && 
        (<div style={{ width: "100%", height: "100%", position: "fixed", background: "black", zIndex: 98 }}>
          <div style={{width:'100%', height:'100%', textAlign:'center', fontSize:'20px', marginTop:'20px', fontWeight:'bold', color:'white'}}>
          {isGettingCamera ? 'Allow Camera Access to Continue' : 'Loading AR Scene...'}
          <div style={{width:'200px', height:'200px', position:'relative',left:'50%', marginTop:'20px', transform:'translate(-50%, 0%)'}}>
            <img style={{width:'200px', height:'200px'}}src='/media/ar/arloading.gif' />
            <img style={{width:'80px', height:'80px', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}}src={isGettingCamera ? '/media/ar/camera-100.png' : '/media/ar/logo.png'} />
          </div>
            
          </div>
          </div>)}
        <div style={{ width: "100%", height: "100%", zIndex: 97 }} ref={sceneRef}>
          {/*userdata?.protected?.isAdmin && <AssetFileList/>*/}
        </div>
      </div>
    </>
  )
}

export default ARScene;