/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react'
import './overview.css'
import GoogleMapReact from 'google-map-react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
// import { useSelector } from 'react-redux'
import moment from 'moment'
import { collection, query, where, orderBy, getDocs, onSnapshot } from "firebase/firestore";
import { useSelector } from 'react-redux'
import { firestore } from '../../../firebase'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { getCards, getMarkers } from '../../../redux/action/card'
// import 'bootstrap/dist/css/bootstrap.css'

function Overview() {
    const dispatch = useDispatch()
    const { id } = useParams()
    const Allcard = useSelector((state: any) => state.Card?.UserCard)
    const Allmarkers = useSelector((state: any) => state.Marker?.UserMarker)
    const [analyticsData, setAnalyticsData] = useState<any>({
        os: {},
        country: {},
        links: {},
        device: {},
        uniqueImpressions: 0,
        totalClicks: 0,
        totalSocailClicks: 0,
        averageEngagementTime: 0,
        lastHourEngagement: 0,
        previousHourPercentage: 0,
        coords: [],
    })
    const [state, setState] = useState({
        start: moment().subtract(24, 'hours'),
        end: moment(),
    })

    const filters = useRef<any>({
        markerId: '',
        sceneId: '',
    })
    const [defScenes, setDefScenes] = useState({})
    const [defMarkers, setDefMarkers] = useState({})
    const coreData = useRef<any>({})
    const [changedData, setChangedData] = useState<any>({})
    // const eventIds = useRef<any>([])
    const [mapProps, setMapProps] = useState<any>({
        center: {
            lat: 32.779167,
            lng: -96.808891,
        },
        zoom: 5,
    })
    const { start, end } = state
    const handleCallback = (start, end) => {
        setState({ start, end })
    }

    useEffect(() => {
        if (!Allcard) return;
        let scenes = {}
        Allcard.forEach((card) => {
            scenes[card._id] = card;
        })
        setDefScenes(scenes)
       //console.log('Allcard', Allcard)
    }, [Allcard])

    useEffect(() => {
        if (!Allmarkers) return;
        let markers = {}
        Allmarkers.forEach((marker) => {
            markers[marker._id] = marker;
        })
        setDefMarkers(markers)
        //console.log('Allmarkers', Allmarkers)
    }, [Allmarkers])

    const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')
    // const card = useSelector((state: any) => state.Card.analytics)
    // const todayCard = useSelector((state: any) => state.Card.todayanalytics)



    useEffect(() => {
        updateValues(changedData)
    }, [changedData])

    useEffect(() => {
       // if (!id) return;
 //finish this later, add dropdown for assets filtering
        dispatch(getCards())
        dispatch(getMarkers())
        
       let sceneId = ''
       let markerId = ''
       const idsp: any = id?.split('-');
       if (idsp[1] === 's') {
        filters.current = {
            sceneId : idsp[0],
            markerId : ''
        }
       }else{
        if (id) {
            if (id === 'all') {
                filters.current = {
                    sceneId : '',
                    markerId :  ''
                }
            }else{
                filters.current = {
                    sceneId : '',
                    markerId :  id
                }
            }
        }
       }

        const fetchInitialData = async () => {
          const uid = localStorage.getItem("userId");
          const analyticsRef = collection(firestore, `users/${uid}/analytics`);
          const { start, end } = state;
          const initialQuery = query(
            analyticsRef,
            where("lastEvent", ">=", start.toDate()),
            orderBy("lastEvent", "asc")
          );

          const querySnapshot = await getDocs(initialQuery);
          let data:any = {};
          let lastTimestamp:any = null;

          querySnapshot.forEach((doc:any) => {
            const docData:any = doc?.data();
            data[doc.id] = docData;
            if (!lastTimestamp || docData.lastEvent?.seconds > lastTimestamp?.seconds) {
              lastTimestamp = docData.lastEvent;
            }
          });

          coreData.current = data;
          setValues();
          if (lastTimestamp) {
            const realTimeQuery = query(
              analyticsRef,
                where("lastEvent", ">=", Date.now()),
                orderBy("lastEvent", "asc")
            );

            const unsubscribe = onSnapshot(realTimeQuery, (snapshot) => {
              let newData:any = {};
              snapshot.docChanges().forEach((change) => {
                console.log('change', change)
                if (change.type === "added" || change.type === "modified") {
                  const docData = change.doc.data();
                  newData[change.doc.id] = docData;
                }
              });
              console.log('newdata', newData)
              setChangedData(newData)
            });

            return unsubscribe;
          }
        };

        const unsubscribe:any = fetchInitialData();

        return () => {
          if (typeof unsubscribe === "function") unsubscribe();
        };
      }, [id, state]);


const updateValues = (changedData:any) => {
    let aggregatedData:any = {...analyticsData}

    Object.keys(changedData).forEach((key: any) => {
        if (!(key in coreData.current)) {
            aggregatedData.uniqueImpressions++;
        }else{
            const entry = changedData[key]
            const lastevents = Object.keys(coreData.current[key].events)
            for (let ekey of Object.keys(entry.events)){
               // console.log('filters', filters.current)
                if (ekey in lastevents) {
                    continue;
                }
               
                const event = entry.events[ekey]
                const res = parseEvent(event, entry, aggregatedData)
                if (!res) {
                    continue;
                }
                
                if (filters.current.markerId && event.markerId !== filters.current.markerId) {
                    continue;
                }

                if (filters.current.sceneId && event.sceneId !== filters.current.sceneId) {
                    continue;
                }
                aggregatedData = res

            }
        }
        coreData.current[key] = {...changedData[key]}
    })

}

const parseEvent = (event:any, entry:any, aggregatedData:any) => {

    if (event.event === 'click') {
        //console.log('click', event)
        aggregatedData.totalClicks++
        aggregatedData.links[event.linkUrl] = aggregatedData.links[event.linkUrl] ? aggregatedData.links[event.linkUrl] + 1 : 1
    }

    if (event.event === 'qr_scan') {
        aggregatedData.device[entry.device] = aggregatedData.device[entry.device] ? aggregatedData.device[entry.device] + 1 : 1
        aggregatedData.city[entry.city] = aggregatedData.city[entry.city] ? aggregatedData.city[entry.city] + 1 : 1
        aggregatedData.state[entry.state] = aggregatedData.state[entry.state] ? aggregatedData.state[entry.state] + 1 : 1
    }

    return aggregatedData
}

const setValues = () => {
        let aggregatedData:any = {
            os: {},
            city: {},
            state: {},
            links: {},
            device: {},
            coords: [],
            uniqueImpressions: 0,
            averageEngagementTime: 0,
            lastHourEngagement: 0,
            previousHourPercentage: 0,
            totalClicks: 0,
            totalSocailClicks: 0,
        }

        let totalEngagementTime = 0; // To accumulate total engagement time in milliseconds
        let sessionCount = 0; // To count the number of sessions
        let lastHourImpressions = 0;
        let previousHourImpressions = 0;

        const now = new Date();
        const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000);
        const twoHoursAgo = new Date(now.getTime() - 2 * 60 * 60 * 1000);
        console.log('filtering here')
        let dataset = Object.fromEntries(
            Object.entries(coreData.current)
          );

        Object.keys(dataset).forEach((key: any) => {

            const entry:any = dataset[key]
            let allow = true;
            for (let ekey of Object.keys(entry.events)){
                const event = entry.events[ekey]
                if (filters.current?.markerId && event.markerId !== filters.current?.markerId) {
                    allow = false;
                    continue;
                }

                if (filters.current?.sceneId && event.sceneId !== filters.current?.sceneId) {
                    allow = false;
                    continue;
                }

                const res = parseEvent(event, entry, aggregatedData)
                //console.log("filters", filters.current)

                if (!res) {
                    continue;
                }
                aggregatedData = res
            }

            if (!allow) {
                return;
            }

              // Convert Firestore Timestamps to JavaScript Date objects
            const sessionStart = entry.sessionStart.toDate();
            const lastEvent = entry.lastEvent.toDate();

            // Calculate the duration in milliseconds
            const engagementDuration = lastEvent - sessionStart;

            // Accumulate the total engagement time
            totalEngagementTime += engagementDuration;
            sessionCount++;

            // Check if the session is within the last hour
            //console.log(lastEvent, oneHourAgo, now)
            if (lastEvent >= oneHourAgo && lastEvent <= now) {
                lastHourImpressions++;
            }

            // Check if the session is within the previous hour
            if (lastEvent >= twoHoursAgo && lastEvent < oneHourAgo) {
                previousHourImpressions++;
            }

            
            

            aggregatedData.coords.push({ lat: entry.lat, lng: entry.long, city: entry.city })
            aggregatedData.uniqueImpressions++;
        })

        if (sessionCount > 0) {
            aggregatedData.averageEngagementTime = (totalEngagementTime / sessionCount) / (1000 * 60); // Convert milliseconds to minutes
        }

                // Calculate the percentage change for the last hour
            aggregatedData.lastHourEngagement = lastHourImpressions;
            if (previousHourImpressions > 0) {
                aggregatedData.previousHourPercentage = ((lastHourImpressions - previousHourImpressions) / previousHourImpressions) * 100;
            } else if (lastHourImpressions > 0) {
                aggregatedData.previousHourPercentage = 100; // If there were no impressions in the previous hour, the increase is 100%
            } else {
                aggregatedData.previousHourPercentage = 0; // No change if there are no impressions in either hour
            }

        if (aggregatedData.coords.length > 0) {
            const lastCoord = aggregatedData.coords[aggregatedData.coords.length - 1];
            setMapProps((prevProps) => ({
                ...prevProps,
                center: {
                    lat: lastCoord.lat,
                    lng: lastCoord.long,
                },
            }));
        }
        console.log(aggregatedData)
        setAnalyticsData(aggregatedData)

}

const Marker = ({ lat, lng, city }: { lat: number; lng: number, city:string}) => (
    <div
        style={{
            position: 'fixed',
            height: '10px',
            width: '10px',
            backgroundColor: 'red',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
        }}
    ><div style={{marginLeft:'12px'}}>{city}</div></div>
);

/*
    useEffect(() => {
        const aggregatedData: any = aggregateAnalyticsData(card?.analytics)
        setAnalyticsData(aggregatedData)
    }, [card, state])
*/
/*
    useEffect(() => {
        const aggregatedDatas: any = aggregateAnalyticsData(todayCard?.analytics)
        setTodayAnalyticsData(aggregatedDatas)
    }, [todayCard])
*/
    //const totalScans: any = Object.values(analyticsData?.country || {}).reduce((acc: any, value) => acc + value, 0);
    //const totalScansToday: any = Object.values(todayanalyticsData?.country || {}).reduce((acc: any, value) => acc + value, 0);
    //const totalClicksToday: any = Object.values(todayanalyticsData?.links || {}).reduce((acc: any, value) => acc + value, 0);
    //const totalScans: any = 0;
    //const totalScansToday: any = 0;
    //const totalClicksToday: any = 0;
    return (
        <>
         <div style={{position:'absolute', margin:'10px', fontSize:'20px'}} >{filters?.current?.sceneId ? `${defScenes[filters?.current?.sceneId]?.name} Analytics` : filters?.current?.markerId ? (<> {filters?.current?.markerId}<img style={{height:'50px'}} src={defMarkers[filters?.current?.markerId]?.compiledImageUrl}/></> ) :  'Overall Project Analytics'}</div>
            <div className='d-flex justify-content-end aiign-items-center'>
               
                <div className='dropdown d-flex gap-3 align-items-center p-5'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                    >
                        <path
                            d='M16.9994 6.81836V15.9998C16.9994 16.5521 16.5517 16.9998 15.9994 16.9998H2C1.44772 16.9998 1 16.5521 1 15.9998V6.81836'
                            stroke='#86898A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M1 3.45508C1 2.90279 1.44772 2.45508 2 2.45508H15.9994C16.5517 2.45508 16.9994 2.90279 16.9994 3.45508V6.81855H1V3.45508Z'
                            stroke='#86898A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M5.36523 1V3.90898'
                            stroke='#86898A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M12.6357 1V3.90898'
                            stroke='#86898A'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                    {/* <button
            className='bg-transparent border-0 dropdown-toggle analytic-dropdown'
            style={{color: '#767676'}}
            type='button'
            id='dropdownMenu2'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            All time data
          </button> */}
                    <DateRangePicker
                        initialSettings={{
                            startDate: start.toDate(),
                            endDate: end.toDate(),
                            ranges: {
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                    moment().subtract(1, 'days').toDate(),
                                    moment().subtract(1, 'days').toDate(),
                                ],
                                'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                                'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                                'This Month': [
                                    moment().startOf('month').toDate(),
                                    moment().endOf('month').toDate(),
                                ],
                                'Last Month': [
                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                ],
                            },
                        }}
                        onCallback={handleCallback}
                    >
                        <div
                            id='reportrange'
                            className='col-4'
                            style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                cursor: 'pointer',
                                padding: '5px 10px',
                                width: '100%',
                                color: "#767676"
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" style={{ marginTop: "-4px" }}>
                                <path d="M16.9994 6.81836V15.9998C16.9994 16.5521 16.5517 16.9998 15.9994 16.9998H2C1.44772 16.9998 1 16.5521 1 15.9998V6.81836" stroke="#86898A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M1 3.45508C1 2.90279 1.44772 2.45508 2 2.45508H15.9994C16.5517 2.45508 16.9994 2.90279 16.9994 3.45508V6.81855H1V3.45508Z" stroke="#86898A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.36523 1V3.90898" stroke="#86898A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12.6357 1V3.90898" stroke="#86898A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>{label}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 8 5" fill="none" style={{ marginTop: "-4px" }}>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.51639 0H0.505307C0.304991 0 0.154753 0.0840336 0.0545948 0.210084C-0.0455635 0.336134 0.00451563 0.504202 0.104674 0.630252L3.61022 4.83193C3.71037 4.95798 3.86061 5 4.01085 5C4.16109 5 4.31132 4.91597 4.41148 4.83193L7.91703 0.630252C8.01718 0.504202 8.01718 0.336134 7.9671 0.210084C7.86695 0.0840336 7.71671 0 7.51639 0Z" fill="#86898A" />
                            </svg>
                        </div>
                    </DateRangePicker>
                </div>
            </div>
            <div className='main-overview'>
                <div className='row my-3'>
                    <div className='text-black fw-semibold overview-title'>Overview <i style={{fontSize:'15px'}}>(last 24 hours</i>)</div>
                    <div className='col-6 col-sm-4 col-md'>
                        <div className='d-flex align-items-center'>
                            <div>
                                <div className='over-main'>{analyticsData.uniqueImpressions}</div>
                                <span className='font-gray2 '>Unique Engagements</span>
                            </div>
                            <div style={{ paddingLeft: '25%' }} className='d-none d-md-block'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='2'
                                    height='30'
                                    viewBox='0 0 2 30'
                                    fill='none'
                                >
                                    <path d='M1 0L1 30' stroke='#DDDDDD' strokeWidth='1.6' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-sm-4 col-md'>
                        <div className='d-flex align-items-center'>
                            <div>
                                <div className='over-main'>{analyticsData.totalClicks}</div>
                                <span className='font-gray2 '>Unique CTA Clicks</span>
                            </div>
                            <div style={{ paddingLeft: '25%' }} className='d-none d-md-block'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='2'
                                    height='30'
                                    viewBox='0 0 2 30'
                                    fill='none'
                                >
                                    <path d='M1 0L1 30' stroke='#DDDDDD' strokeWidth='1.6' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-sm-4 col-md'>
                        <div className='d-flex align-items-center'>
                            <div>
                                <div className='over-main'>{analyticsData.totalSocailClicks}</div>
                                <span className='font-gray2 '>Unique Social Clicks</span>
                            </div>
                            <div style={{ paddingLeft: '25%' }} className='d-none d-md-block'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='2'
                                    height='30'
                                    viewBox='0 0 2 30'
                                    fill='none'
                                >
                                    <path d='M1 0L1 30' stroke='#DDDDDD' strokeWidth='1.6' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-sm-4 col-md'>
                        <div className='d-flex  align-items-center'>
                            <div>
                                <div className='over-main d-flex align-items-center'>
                                    {analyticsData.averageEngagementTime.toFixed(1)}{' '}
                                    <div className='d-flex flex-column'>
                                        <span className='font-black fs-6  fw-semibold'>Mins</span>{' '}
                                    </div>
                                </div>
                                <span className='font-gray2 '>Average engagement time</span>
                            </div>
                            <div style={{ paddingLeft: '25%' }} className='d-none d-md-block'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='2'
                                    height='60'
                                    viewBox='0 0 2 60'
                                    fill='none'
                                >
                                    <path d='M1 0L1 60' stroke='#DDDDDD' strokeWidth='1.6' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-sm-4 col-md '>
                        <div className='over-main font-dark1'>
                           {analyticsData.lastHourEngagement} <span className='font-gray2 fs-6 fw-semibold'>{analyticsData.previousHourPercentage}%</span>
                        </div>
                        <span className='font-gray2 '>Engagements in last hour</span>
                    </div>
                </div>
            </div>
            <div className='main-map mt-5'>
                <div style={{ padding: '20px' }}>
                    <div className='d-flex justify-content-between align-items-center pb-2'>
                        <div className='overview-title'>Users Geography</div>
                    </div>

                    <div style={{ height: '60vh', width: '100%' }}>
                       <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyBG-ra12MZJTnIObQoSRfs6zYRGD8hP-0Y" }}
                        center={mapProps.center}
                        zoom={mapProps.zoom}
                    >
                        {analyticsData.coords.map((coord: any, index) => (
                            <Marker key={`${coord.lat}-${coord.lng}-${index}`} lat={coord.lat} lng={coord.lng} city={coord.city} />
                        ))}
                    </GoogleMapReact>
                    </div>
                </div>
            </div>
            <div className='row font-dark1 my-5'>
                <div className='col-12 col-sm-3 d-flex flex-column '>
                    <div className='main-scancard mb-sm-2'>
                        <h3 className='p-3'>Engagement by state</h3>
                        <div>
                            {Object.entries(analyticsData?.state || {}).map(([key, value]: any) => (
                                <div  key={`state-${key}`} className='p-3 border-bottom border-secondary d-flex justify-content-between'>
                                    <div>🏁 {key}</div>
                                    <div>{value}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-3 d-flex flex-column '>
                    <div className='main-scancard mb-sm-2'>
                        <h3 className='p-3'>Engagement by city</h3>
                        <div>
                            {Object.entries(analyticsData?.city || {}).map(([key, value]: any) => (
                                <div key={`city-${key}`}  className='p-3 border-bottom border-secondary d-flex justify-content-between'>
                                    <div>🏁 {key}</div>
                                    <div>{value}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-3 d-flex flex-column'>
                    <div className='main-scancard'>
                        <h3 className='p-3'>Engagement by link</h3>
                        <>
                            <div>
                                {Object.entries(analyticsData?.links || {}).map(([key, value]: any) => (
                                    <div key={`links-${key}`} className='p-3 border-bottom border-secondary d-flex justify-content-between'>
                                        <div>{key}</div>
                                        <div>{value}</div>
                                    </div>
                                ))}
                            </div>
                        </>
                    </div>
                </div>
                <div className='col-12 col-sm-3 d-flex flex-column'>
                    <div className='main-scancard'>
                        <h3 className='p-3'>Engagement by OS</h3>
                        {Object.entries(analyticsData?.device || {}).length > 0 &&
                            Object.entries(analyticsData?.device || {}).map(([key, value]: any) => (
                                <div key={`dev-${key}`} className='p-3 border-bottom border-secondary d-flex justify-content-between'>
                                    <div>{key}</div>
                                    <div>{value}</div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview
